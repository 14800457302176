@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Lexend&family=Poppins&display=swap');

.navbar {
    background: linear-gradient(90deg, #102444 1.6%, #124DAC 119.06%), #D9D9D9;
    height: 80px;
    display: flex;
    
    justify-content: space-between;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
   
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  .sign-button {
    width: 82px;
    height: 38px;
    font-size: 14px;
    font-weight: 500;
    border-width: 0; 
    border-radius: 5px;
    background: linear-gradient(134.87deg, #FFAC31 6.55%, #DD8500 52.87%);
    color: white;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    /* margin-right: 220px;
    margin-left: 20px; */
  }
  /* .emptycontainer {
    display: flex;
    margin-right: 650px;
  } */
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
    
  }
  
  .nav-item {
    height: 80px;
    
  }
  
  .nav-links:hover {
    color: rgba(255, 177, 60, 1);
   
   
    
   
  }
  
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0rem 1rem;
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    padding-right: 20px;

    font-family: 'Lexend', sans-serif;
    text-transform: uppercase;
  }


  .nav-links:active{
    color: rgba(255, 177, 60, 1);
};
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
    color: #fff;
    
    margin-top: 10px;
    
  }
  
  @media screen and (max-width: 960px) {
    .container {
        width: 500px;
    }
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: min-content;
      height: 80vh;
      position: absolute;
      top: 60px;
      left: -130%;
      opacity: 1;
      transition: all 0.5s ease;
      
      border-bottom-right-radius: 20px;
      justify-content: center;
     
    }
  
    .nav-menu.active {
      background: linear-gradient(90deg, #102444 1.6%, #124DAC 119.06%);
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
    .nav-links:disabled {
      color: rgba(255, 177, 60, 1);
    }
  
  
    .nav-links:hover {
      color: rgba(255, 177, 60, 1);
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 0px;
      
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
  }

 

  @media (min-width: 1280px) and (max-width: 1300px) {



  }


  /* @media only screen and (min-width: 1400px) { 

.navbar-logo {
  margin-right: 950px;
}

  }

  @media only screen and (min-width: 1100px) { 
    .navbar-logo {
      margin-right: 130px;
    }

  }
  @media only screen and (min-width: 1200px) { 
    .navbar-logo {
      margin-right: 130px;
    }

  }
  @media only screen and (min-width: 1700px) { 

    .navbar-logo {
      margin-right: 1350px;
    }
    
      }

  @media only screen and (min-width: 960px) { 
    .navbar-logo {
      margin-right: 120px;
    }
  } */


  /* @media only screen and (min-width: 1100px) { 
    .navbar-logo {
      margin-right: 360px;
    }
  } */