@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');





.pricing-container {
    background-color: #F9FAFF;
    justify-content: center;
   display: flex;
   flex-direction: column;
    
}

.pricing-card {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F9FAFF;
    margin-bottom: 30px;
}
.topline {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: rgba(71, 113, 180, 1);
}
.title {
    display: flex;
    align-items: center;
    justify-content: center; 
 margin-bottom: 60px;
 color: 
 rgba(150, 150, 150, 1);
}

.card-basic,
.card-premium,
.card-standard {
  margin: 0 2rem 1rem 0;
  padding: 0 0 0.5rem 0;
  width: 17rem;
  
  
  color: #444;
  text-align: center;
  border-radius: 1rem;
 
  overflow: hidden;
  transition: all 0.1ms ease-in-out;
  height: 550px;
  box-shadow: 0.5rem 0.5rem 1rem rgba(51, 51, 51, 0.3);
}
.card-basic:hover,
.card-premium:hover,
.card-standard:hover {
  transform: scale(1.04);

 
}

.card-basic,
.card-premium {
    background-color: rgba(255, 255, 255, 1);
    
}



.card-body {
  padding: 0.5rem 0;
}
.card-body h2 {
  font-size: 2rem;
  font-weight: 700;
}

.card-element-container {
  color: rgba(12, 0, 71, 1);
  list-style: none;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  margin-top: 30px
}
.card-element-container-standard {
    color: #fff;
    list-style: none;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    text-align: start;
    font-size: 12px;
    font-weight: 500;
    margin-top: 30px
  }

.card-elememt-main{
    
    margin:7px;
}

.card-element {
    margin-left: 5px;
}


.card-element-hidden {
  display: none;
}
.card-header {
    margin-top: 30px;
    margin-bottom: 20px
}
.header-basic {
    font-size: 22px;
    font-weight: 700;
    color: rgba(12, 0, 71, 1);
}

.header-premium {
    font-size: 22px;
    font-weight: 700;
    color: rgba(12, 0, 71, 1);
}

.header-standard {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
}

.card-standard {
    background-color: rgba(65, 131, 236, 1);
    color: #fff;
}
.header-description {
    color: rgba(150, 150, 150, 1);
    width: 240px;
    font-size: 14px;

}

@media screen and (max-width: 760px) { 
    .pricing-card {
        display: flex;
        justify-content: center;
        align-items:center ;
        margin-left: 30px;
    }
    .title {
        margin-left: 10px;
        text-align: center;
    }
}