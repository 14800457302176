
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Lexend&family=Poppins&display=swap');
:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background: linear-gradient(134.87deg, #FFAC31 6.55%, #DD8500 52.87%);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.19);
    color: #fff;
    padding: 8px 20px;
    font-size: 4px;
    font-weight: 600;
    
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 12px 10px;
    font-size: 16px;
    font-family: 'Lexend', sans-serif;
    font-weight: 500;
  }
  
  .btn--large {
    padding: 7px 7px;
    font-size: 15px;
  }
  
  .btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }
  
  .btn--wide {
    padding: 15px 22px;
    font-size: 20px;
  }
  .btn--wider {
    padding: 12px 18px;
    font-size: 20px;
  }
  .btn--widerr {
    padding: 10px 78px;
    font-size: 20px;
  }
  
 
  
  .btn--mobile:hover {
    transition: all 0.3s ease-out;
    background: rgb(184, 181, 181);
    color: #242424;
    box-shadow: 0px 4px 30px #CED5F0;
  }
  
  .btn--wide:hover {
    color: #fff;
    background-color: black;
    transition: all 0.2s ease-out;
  }
  .btn--wider:hover {
    color: #fff;
    background-color: black;
    transition: all 0.2s ease-out;
  }
  .btn--widerr:hover {
    color: #fff;
    background-color: black;
    transition: all 0.2s ease-out;
  }
  
  .btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
  }
  
  .blue {
    background:  #112E5B;
    color: #fff;
    border: none;
  }


  .lightblue {
    background: #4183EC;
    color: #fff;
    border: none;
  }
  .white {
    background: #fff;
    color: black;
    border: none;
  }

  .lightyellow {
    background: #EFA22F;
    color: #fff;
    border: none;
  }
  .lightpurple {
    background: #6551B2;
    color: #fff;
    border: none;
  }
  
  .red {
    background-color: #f00946;
    color: #fff;
    border: none;
  }
  
  .primary {
    background-color: #242424;
    color: #fff;
    border: none;
  }
  
  .primary:hover {
    background-color: #fff;
    color: #242424;
    border: none;
  }
  
  .green {
    background: #25ce4a;
    color: #fff;
    border: none;
  }
  
  .green:hover {
    background-color: #242424;
  }