@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Lexend&family=Poppins&display=swap");

.mainContainer {
  display: flex;
}

.container {
  display: flex;
  flex-direction: row;
  width: 1000px;
}

.tabs {
  display: flex;
  height: 108px;
  justify-content: center;
  align-items: center;
}

.tabs button {
  border: none;
  cursor: pointer;
  padding: 20px 10px;
  /* width: 100%; */
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.5s ease-out;
}

.tabs button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
}

.tab1 .tabs button:disabled {
  background: rgba(228, 238, 255, 1);
  color: white;
  border-bottom-color: #0000ff;
  border-bottom-width: 2px;
}
.tab1 .tabs button:hover {
  background-color: rgba(228, 238, 255, 1);
}
.tab2 .tabs button:hover {
  background-color: rgba(255, 241, 221, 1);
}
.tab2 .tabs button:disabled {
  background: rgba(255, 241, 221, 1);
  color: white;
}
.tab3 .tabs button:disabled {
  background: rgba(245, 240, 255, 1);
  color: white;
}
.tab4 .tabs button:disabled {
  background: rgba(228, 238, 255, 1);
  color: white;
}
.tab5 .tabs button:disabled {
  background: rgba(255, 241, 221, 1);
  color: white;
}
.tab6 .tabs button:disabled {
  background: rgba(245, 240, 255, 1);
  color: white;
}

.title {
  color: black;
  font-size: medium;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.content {
  padding: 50px 80px;
  background-color: white;
  font-weight: 300;
  line-height: 30px;
  font-size: 16px;
  /* text-align: justify; */
}

.content,
button {
  font-size: 15px;
}
.demo-main-button {
  background: linear-gradient(117.12deg, #ffad31 34.21%, #de8501 85.11%);
  border-width: 0;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 60px;
}

.head-wrapper-img {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.content-wrap-one {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.content-wrap-two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.main-heading {
  font-size: 40px;
  color: rgba(33, 112, 238, 1);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-left: 60px;
}
.title-description {
  font-size: 25px;
  margin-top: 30px;
  font-family: "Lexend", sans-serif;
  margin-left: 60px;
}
.description-one {
  margin: 20px;
  font-size: 20px;
  margin-top: 40px;
  font-weight: 100;
  font-family: "Lexend", sans-serif;
}

.first-heading {
  font-family: "Lexend", sans-serif;
  font-size: 32px;
  color: rgba(63, 133, 245, 1);

  font-weight: 500;
  text-align: initial;
  margin-left: 20px;
}

.small-heading {
  margin-top: 120px;
  color: rgba(90, 101, 119, 1);
  font-size: 16px;
  font-family: "Lexend", sans-serif;
  font-weight: 500;
  text-align: initial;
  margin-left: 20px;
}
/* 
@media only screen and (min-width: 960px) {
    .tabs {
        width: 850px;
      }
} */
@media only screen and (max-width: 960px) {
  .content {
    padding: 50px;
  }

  .head-wrapper-img {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .content-wrap-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .content-wrap-two {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
/* 
@media only screen and (max-width: 560px) {
    .tabs {
        width: 200px;
      }
      .container {
        width: 100px;
      }
} */

/* @media only screen and (max-width: 340px) {
    .tabs {
        width: 150px;
      }
} */

/* @media only screen and (max-width: 500px) {
  .tabs {
      width: 16%;
    }
} */

@media (min-width: 200px) and (max-width: 460px) {
  .title-description {
    margin: 20px;
  }
  .content {
    margin: 0px;
    padding: 0px;
  }
  .content-wrap-one {
    margin: 20px;
  }
  .content-wrap-two {
    margin: 20px;
  }
  .content-mini {
    margin: 20px;
  }
  .description-one {
    margin: 20px;
  }
  .first-heading {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1110px) {
}

@media only screen and (max-width: 370px) {
  .fadeshow1 {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .hidden1 {
    display: none;
  }
}
