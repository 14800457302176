@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Lexend&family=Poppins&display=swap');

.top-line {
    font-size: 36px;
    font-family: 'Lexend', sans-serif;
    margin-top: 70px;
    text-align:initial;
    margin-left: 25px;
}



.solution-heading {
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
}
.solution-description {
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(78, 78, 78, 1);
}
.wraper-full {
    margin-left: 70px;
    margin-right: 70px;
  }

.learnbtn {
    margin-bottom: 50px;
    margin-top: 20px;
    width: 200px;
    margin-left: 30px;
}
.solution-section {
    
  margin: 0px;
}
.colWrapper {
    display: flex;
    flex-direction: row;
}
.headline {
    font-size: 16px;
    font-family: 'Lexend', sans-serif;
    margin-left: 25px;
}

.imgwrap {
    margin-left: 50px;
    margin-right: 50px;
}




@media screen and (max-width: 650px) {
    .solution-blow {
        display: none !important;
    }

   

/* .colWrapper {
    display: flex;
    flex-direction: column;
} */

.wraper-full {
    display: flex;
    flex-direction: row-reverse; 
    flex-wrap: wrap;
    justify-content: space-around;
}
.learnbtn {
    display: flex;
    /* align-items: center; */
    
}
.imgwrap {
    width: 280px;
}
.main-banner {
    width: 200px;
}


}




@media (min-width: 650px) and (max-width: 960px) {
    .imgwrap {
        width: 280px;
        margin: 0px;
    }

    .solution-description{
        width: 300px;
    }

    .solution-section {
        padding: 0px;
        margin: 0px;
    }

    .solutiom-text-wrapper {
        width: 620px;
    }

    .container {
        width: 700px;
    }

    .wraper-full {
        margin-left: 0px;
        margin-right: 0px;
      }


}

@media (min-width: 960px) and (max-width: 1100px) {
    .imgwrap {
        width: 500px;
        margin: 0px;
    }

    .solution-section {
        padding: 0px;
        margin: 0px;
    }

    .solutiom-text-wrapper {
        width: 620px;
    }

    .container {
        width: 600px;
    }

    .wraper-full {
        margin-left: 0px;
        margin-right: 0px;
      }

}




@media (min-width: 400px) and (max-width: 560px) {
    .imgwrap {
        width: 280px;
    }
    .wraper-full {
        margin-left: 0px;
        margin-right: 0px;
        width: 300px;
      }

    .imgwrap {
        padding-right: 20px;
       }
    
    /* .solution-section {
        margin-left: 20px;
    } */

    .imgwrap {
        margin-left: 0px;
        margin-right: 0px;
    }

}

@media (min-width: 300px) and (max-width: 400px) {
   
    .wraper-full {
        margin-left: 0px;
        margin-right: 0px;
        width: 300px;
      }
   
      .imgwrap {
      margin: 30px;
        width: 280px;
        justify-content: center;
        align-items: center;
    }
    /* .solution-section {
        margin-left: 20px;
    } */

    .headline {
        width: 280px;
    }
    .top-line {
        margin-left: 30px;
        width: 280px;
    }
}




@media (min-width: 1280px) and (max-width: 1300px) {


   .solution-description {
    margin: 8px;
    width: 550px;
   }
   .solutiom-text-wrapper {
    width: 1200px;
}

.container {
  
    padding-right: 0px;
}

.imgwrap {
    width: 440px;
}


}

