@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Lexend&family=Poppins&display=swap');

.main-title {
    font-size: 34px;
    font-family: 'Lexend', sans-serif;
    
    margin-bottom: 30px;

}
.description-main {
    font-family: 'Lexend', sans-serif;
    font-size: 22px;
    
}

.description-two {
    font-family: 'Lexend', sans-serif;
    font-size: 22px;
}

.break {
    margin-bottom: 20px;
}
@media screen and (max-width: 760px) {
  
    .main-wrapper {
        display: flex;
        flex-wrap: wrap
        
    }
}