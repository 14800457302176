@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Lexend&family=Poppins&display=swap');
.feature-section {
    margin-top: 100px;
    display: flex;
   
}
.topline {
    color: rgba(23, 99, 219, 1);
    font-size: 36px;
    font-family: 'Lexend', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 40px;
}
.feature-description-main {
color: 
rgba(118, 131, 180, 1);
font-size: 20px;
margin-top: 10px;
font-family: 'Lexend', sans-serif;


text-align: center;
}
.feature-title {
width: 300px;
color: rgba(66, 118, 200, 1);
font-family: 'Lexend', sans-serif;
text-transform: uppercase;
margin-bottom: 10px;
font-weight: 500;
}
.feature-description {
    width: 330px;
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    font-family: 'Lexend', sans-serif;
   color: rgba(118, 131, 180, 1);
   font-weight: 500;

}
.feature-img {
    margin-bottom: 10px;
   
}
/* @media screen and (min-width: 1600px) {
    .feature-section {
        display: flex;
        width: 600px;
        
    }
} */
/* .col-feature-description {
    width: 500px;
} */
@media screen and (max-width: 760px) {

    .feature-description-main {
        width: 300px;
       /* padding-right: 140px; */
    }
    .feature-section {
        display: flex;
        width: 300px;
        
    }
    .feature-description {
        width: 300px;
    }
    /* .feature-description-main {
        width: 300px;
    } */
    .topline {
        font-size: 30px;
        justify-content: center;
    }

 
}



/* @media screen and (max-width: 960px) {

    .feature-content {
        width: 600px;
    }
} */

/* @media screen and (min-width: 760px) {
    .feature-content {
        width: 600px;
    }

} */

/* @media screen and (min-width: 960px) {

} */






@media (min-width: 340px) and (max-width: 500px) {
    .feature-section {
        display: flex;
   margin-left: 10px;
        
    }

}

@media (min-width: 500px) and (max-width: 600px) {
    .feature-section {
        display: flex;
   margin-left: 40px;
        
    }

}

@media (min-width: 600px) and (max-width: 700px) {
    .feature-section {
        display: flex;
   margin-left: 130px;
        
    }

}

@media (min-width: 700px) and (max-width: 760px) {
    .feature-section {
        display: flex;
   margin-left: 260px;
        
    }

}
@media (min-width: 1400px) and (max-width: 2600px) {
   
    .feature-content {
        width: 1280px;
        /* padding-right: 100px; */
        margin-left: 60px;

    }
}
@media (min-width: 760px) and (max-width: 960px) {
   
    .feature-content {
        width: 800px;
        /* padding-right: 100px; */
        margin-left: 20px;

    }

    .feature-description-main {
        width: 700px;
       /* margin-right: 100px; */
       margin-left: 0px;
      
    }

    .feature-section {
        /* margin-right: 270px; */
        margin-left: -50px;
    
        width: 20px;

    }

  }

  @media (min-width: 860px) and (max-width: 960px) {
   
  

    .feature-section {
        /* margin-right: 270px; */
        margin-left: 20px;
       
        width: 20px;

    }

  }


  @media (min-width: 1200px) and (max-width: 1300px) {

    .feature-description-main {
        width: 916px;
    }
  }
