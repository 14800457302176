.footer-container-main {
    background-color: #356CC4;
    padding: 2rem 0 2rem 3rem;
}


.footer-container {
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  margin-top: 20px;

    /* padding: 24px; */
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 124px;
    font-size: 18px;
    width: 300px;
    font-family: 'Lexend', sans-serif;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 24px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* margin-bottom: 50px */
  }
  
  .footer-link-wrapper {
    display: flex;
    align-items: flex-start
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px;
    text-align: left;
    width: 160px;
 
    
    box-sizing: border-box;
    justify-content: center;
  }
  
  
  .footer-link-items h2 {
  
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .footer-link-items > h2 {
    color: rgba(236, 240, 255, 1);
  }
  
  .footer-link-items a {
    color: rgba(236, 240, 255, 1);
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 0.3rem;
    font-weight: 400;
    /* padding-bottom: 20px; */
  }
  
  .footer-link-items a:hover {
    color: #EFA22F;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 1000px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
    .footer-container {
        display: flex;
        flex-direction: column;
    }
  }

  @media (min-width: 320px) and (max-width: 360px) {
    .footer-subscription {
      width: 280px;
    }

    .footer-subscription-heading {
      width: 300px;
    }
    .footer-link-items {
      width: 120px;
    }
   
  }

  @media (min-width: 360px) and (max-width: 420px) {
    .footer-subscription {
      width: 280px;
    }
    .footer-container-main {
      padding-left: 0px;
    }
   
    .footer-subscription-heading {
      width: 300px;
    }
  }