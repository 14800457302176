@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Lexend&family=Poppins&display=swap');

.home-btn {
  display: flex;
  
  width: 100%;
}
.home-btn1 {
  margin-right: 10px;
  font-family: 'Lexend', sans-serif;
  font-weight: 500;

  
}

.home-text-wraper {
  margin-left: 20px;
} 
.small-wrap {
  margin-left: 40px;
}

.box-contain {

  position: "absolute";
  top: "50%";
  left: "50%";
  background-color: #112E5B;
  transform: "translate(-50%, -50%)";
  width: '40%';
  height: '40%';
  
  border-radius: 5;
  box-shadow: 24;
}
.heading {
  color: rgba(19, 71, 155, 1);
  font-size: 32px;
  font-weight: 500;
  width: 600px;
  font-family: "Poppins", sans-serif;
  margin-top: 80px;
  margin-bottom:40px
  
}
.home-description {
  color: rgba(28, 37, 69, 1);
 
  font-family: 'Lexend', sans-serif;
display: flex;
  font-weight: 400;
  align-items: center;
  margin-bottom: 50px;
  font-size: 20px;
  padding-right: 70px;
  /* width: 600px; */
  
  /* text-align: center; */
}
.di-title {
  font-size: 21px;
  font-family: "Poppins", sans-serif;
}


.img-des {
  display: flex;
  /* flex-direction: row; */
  margin-top: 80px;
  /* justify-content: space-between; */
}
.home-bottom,
.bottom-req {
  width: 100%;
  height: 100%;
  position: absolute;
  
}
.home-bottom {
  height: 88px;
  background-color: #e2e6ff;

  display: flex;

  justify-content: flex-end;
}
.bottom-req {
  height: 60px;
  width: 500px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 150px;
  margin-bottom: 50px;
  bottom: 20px;
  position: relative;
  z-index: 20;
  box-shadow: 0px 2.64234px 19.8175px #ced5f0;
}
.home-btn3 {
  margin-left: 10px;
  margin-right: 20px;
}

.left-img {
   display: flex;
  flex-direction: column;
 
}
.main-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 30px;
 
}




@media screen and (max-width: 760px) {


  .main-wrap {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }
  .left-img {
   
    flex-direction: column;
  }
  .heading {
    color: rgba(19, 71, 155, 1);
    font-size: 32px;
    font-weight: 500;
  width: 300px;
    font-family: "Poppins", sans-serif;
    justify-content: center;
    
  }
  .img-des {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
  }
  .home-description {
    color: rgba(72, 83, 126, 1);

    font-family: 'Lexend', sans-serif;
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-top: 50px;
    margin-bottom: 50px;
    /* width: 300px; */
    justify-content: center;
  }
  .home-bottom {
    height: 0px;
    background-color: transparent;

    display: flex;
    margin-top: 40px;
   
    justify-content: center;
  }
  .bottom-req {
    display: flex;

    background-color: #fff;
    
    align-items: center;
    justify-content: center;
    margin-left: 120px;
   
   
    box-shadow: 0px 2.64234px 19.8175px #ced5f0;
    border-radius: 10px;
  }
  .home-img {
    width: 250px;
  }

  .home-input {
    width: 140px;
  }

  .home-btn3 {
    margin-right: 0px;
  }
 
  .left-two-img {
    display: none;
  }
  .left-three-img {
    display: none;
  }
  .home-left-img {
    display: none;
  }
}

@media screen and (min-width: 760px) {

  .home-left-img {
    
    width: 350px;
    justify-content: center;
  
   margin-top: 100px

    
    
  }

  .home-description {
    /* width: 300px; */
   
   margin-top: 100px;
   
  }

  .home-img {
    margin-bottom: 30px;
  }

  .heading {
    font-size: 22px;
    width: 400px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 860px ) {
  .heading {
    font-size: 22px;
    width: 400px;
    margin-right: 20px;
  }
  .small-wrap {
    width: 400px;
  }
/* 
  .home-description {
    width: 330px;
   

  
  } */
}



@media only screen and (min-width: 900px ) {

  .home-left-img {
    width: 400px;
    justify-content: center;
    margin-top: 100px;
    
    
  }
  .home-description {
    /* width: 360px; */
  
   margin-top: 100px;
 
  }

  .home-img {
    width: 250px;
  }
  /* .img-des {
    justify-content:flex-start;
   

  } */
  .main-wrap {
    justify-content: center;
  }

  .heading {
   
    margin-right: 100px;
  }
  
}
/* @media only screen and (min-width: 1100px ) {

  .home-left-img {
    width: 600px;
   
    margin-top: 0px;
    padding: 0px;
    flex: 1;
    
  }
  .home-img {
    width: 350px;
  }

  .heading {
    font-size: 22px;
    width: 500px;
    margin-right: 20px;
  }

  
} */

/* @media only screen and (min-width: 1200px ) {

  .home-description {
    width: 500px;
    margin-top: 100px;
    margin-right: 20px;
  }

} */
@media (min-width: 1600px) and (max-width: 1700px) {
  .home-left-img {
    width: 700px;
    
    
  }

  .heading {
    font-size: 22px;
    width: 600px;
   
  }

  .small-wrap {
    width: 500px;
  }
  
  .main-wrap {
    margin-left: 200px;
  }
}
@media only screen and (min-width: 2000px ) {
  .home-left-img {
    width: 700px;
    
    
  }

  .heading {
    font-size: 22px;
    width: 600px;
   
  }

  .small-wrap {
    width: 500px;
  }
  
  .main-wrap {
    margin-left: 300px;
  }
  .home-description {
    width: 900px;
  }
}
@media (min-width: 1700px) and (max-width: 1800px) {
  .home-left-img {
    width: 700px;
    
    
  }

  .heading {
    font-size: 22px;
    width: 600px;
   
  }

  .small-wrap {
    width: 500px;
  }
  
  .main-wrap {
    margin-left: 220px;
  }
}
@media (min-width: 1800px) and (max-width: 1900px) {
  .home-left-img {
    width: 700px;
    
    
  }

  .heading {
    font-size: 22px;
    width: 600px;
   
  }

  .small-wrap {
    width: 500px;
  }
  
  .main-wrap {
    margin-left: 240px;
  }
}
@media (min-width: 1900px) and (max-width: 2000px) {
  .home-left-img {
    width: 700px;
    
    
  }

  .heading {
    font-size: 22px;
    width: 600px;
   
  }

  .small-wrap {
    width: 500px;
  }
  
  .main-wrap {
    margin-left: 260px;
  }
}

@media (min-width: 1500px) and (max-width: 1600px) {
  .home-left-img {
    width: 700px;
    
    
  }

  .heading {
    font-size: 22px;
    width: 600px;
   
  }

  .small-wrap {
    width: 500px;
  }
  
  .main-wrap {
    margin-left: 140px;
  }
}
@media (min-width: 1400px) and (max-width: 1500px) {
  .home-left-img {
    width: 700px;
    
    
  }

  .heading {
    font-size: 22px;
    width: 600px;
   
  }

  .small-wrap {
    width: 500px;
  }
  
  .main-wrap {
    margin-left: 120px;
  }
}
@media (min-width: 1300px) and (max-width: 1400px) {

  .home-left-img {
    width: 700px;
    
    
  }

  .heading {
    font-size: 22px;
    width: 600px;
   
  }

  .small-wrap {
    width: 500px;
  }
  
  .main-wrap {
    margin-left: 80px;
  }
  
}
/* @media only screen and (min-width: 1700px ) { 
  .main-wrap {
    margin-left: 160px;
  }
  .small-wrap {
    width: 400px;
  }
  .home-left-img {
    width: 700px;
    
    
  }
} */




/* 
@media only screen and (min-width: 1500px ) {

  .home-left-img {
    width: 1000px;
    
    
  }
  .small-wrap {
    width: 400px;
  }
  .main-wrap {
    margin-left: 80px;
  }


} */


/* 
@media (min-width: 1980px) and (max-width: 2000px) {

  .home-left-img {
    width: 1300px;
    
    
    
  }
  .small-wrap {
    width: 600px;
  }

  .heading {
   margin-right: 100px;
  }

  .main-wrap {
    margin-left: 140px;
  }

} */
@media (min-width: 600px) and (max-width: 760px) {
.main-wrap {
  margin-left: 100px;
}

.heading {
  width: 500px;
}
/* .home-description {
  width: 400px;
} */

.home-text-wraper {
  margin-left: 20px;
}


}

@media (min-width: 760px) and (max-width: 860px) {
  .main-wrap {
    margin-left: 20px;
  }
.small-wrap {
  width: 300px;
}
  .heading {
    width: 320px;
  }

  .home-img {
   width: 200px;
  }
  .home-left-img {
    width: 360px;
   
    margin-top: 0px;
    padding: 0px;
    flex: 1;
    
  }
  
}

@media (min-width: 500px) and (max-width: 600px) {
  .main-wrap {
    margin-left: 50px;
  }
  
  .heading {
    width: 400px;
  }
  /* .home-description {
    width: 400px;
  } */

  .home-text-wraper {
    margin-left: 20px;
  }



  
  }

  @media (min-width: 460px) and (max-width: 500px) {
    .main-wrap {
      margin-left: 20px;
    }
    
    .heading {
      width: 400px
    }
   .home-text-wraper {
      margin-left: 20px;
    } 
    }



    @media (min-width: 1200px) and (max-width: 1300px) {

      .home-left-img {
        width: 680px;
       
        margin-top: 0px;
        padding: 0px;
        flex: 1;
        
      }

      .small-wrap {
        width: 500px;
        
      }


      .heading {
        width: 550px;
        height:148px;
        font-size: 32px;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        margin-top: 50px;
        
      }

      .home-description {
       margin-bottom: 60px;
       margin-top: 0px;
      width: 590px;
     
     
      }

      .home-img {
        width: 375px;
        height: 423px;
        
      }

    }
    @media (min-width: 320px) and (max-width: 350px) {
      .heading {
        width: 250px;
      }

      .bottom-req {
        width: 300px;
        margin-right: 0px;
        margin-left: 0px;
      }
      .home-text-wraper {
        margin: 0px;
      }
    }

    @media (min-width: 350px) and (max-width: 420px) {
      /* .heading {
        width: 250px;
      } */

      .bottom-req {
        width: 300px;
        margin-right: 0px;
        margin-left: 0px;
      }
      .home-text-wraper {
        margin-left: 0px;
      } 
      .box-contain {
        width: 200px;
      }
    }


   
/* 
    @media (min-width: 400px) and (max-width: 500px) {
      .home-description {
        width: 350px;
       
      }
    }

    @media (min-width: 300px) and (max-width: 400px) {
      .home-description {
        width: 280px;
       
      }
    } */

    #main-button {
      background: linear-gradient(117.12deg, #134699 34.21%, #112E5B 85.11%);
      border-radius: 5px;
      color: #fff;
    }